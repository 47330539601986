<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <h4>Vendedores</h4>
        <el-col :md="15">
          <el-row type="flex" justify="end">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="medium"
              @click="openSellerModal(null)"
            ></el-button>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Sellers"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="name" label="nome"> </el-table-column>
      <el-table-column label="ações" :min-width="80">
        <template #default="cat">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                @confirm="
                  updateSeller({
                    is_active: !cat?.row?.is_active,
                    uid: cat?.row?.uid,
                  })
                "
                :title="
                  cat?.row?.is_active
                    ? 'Desativar vendedor?'
                    : 'Ativar vendedor?'
                "
              >
                <template #reference>
                  <el-button
                    onlyIcon
                    :type="cat.row.is_active ? 'primary' : 'danger'"
                    circle
                    ><em
                      :class="{
                        'el-icon-lock': !cat.row.is_active,
                        'el-icon-unlock': cat.row.is_active,
                      }"
                    ></em
                  ></el-button>
                </template>
              </el-popconfirm>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openSellerModal(cat.row)"
              >
                <i class="el-icon-edit"></i
              ></el-button>
              <el-popconfirm
                @confirm="deleteSeller(cat.row)"
                title="
              Remover vendedor?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <seller-modal
      :showModal="showSellerModal"
      :seller="seller"
      @close-modal="showSellerModal = false"
      @should-update="fetchSellers"
    ></seller-modal>
  </el-card>
</template>

<script>
import SellerModal from "./modals/SellerModal.vue";
import { ElNotification } from "element-plus";
import SellerService from "../services/sellers";

export default {
  name: "SellersPage",
  components: { SellerModal },
  data: () => ({
    hasError: false,
    isLoading: true,
    sellers: null,
    seller: null,
    showSellerModal: false,
  }),
  mounted() {
    this.fetchSellers();
  },
  computed: {
    Sellers() {
      return this.sellers || [];
    },
  },
  methods: {
    async deleteSeller(_s) {
      if (_s) {
        const { error } = await SellerService(_s?.uid).delete();

        if (error)
          this.$notify({
            title: "Não foi possível remover o vendedor",
            type: "error",
            message: error.message,
            position: "bottom-right",
          });
        else {
          this.fetchSellers();
          this.$notify({
            title: "Vendedor removido com sucesso.",
            type: "success",
            position: "bottom-right",
          });
        }
      }
    },
    openSellerModal(e) {
      (this.seller = e), (this.showSellerModal = true);
    },
    async updateSeller(_s) {
      if (_s) {
        const { seller, error } = await SellerService(_s?.uid).update(_s);

        if (error)
          ElNotification.error({
            title: "Não foi possível atualizar o vendedor",
            message: error.message,
            position: "bottom-right",
          });
        else {
          ElNotification.success({
            title: seller.message,
            position: "bottom-right",
          });
          this.fetchSellers();
          this.isLoadingSave = false;
          this.shouldShow = false;
        }
      }
    },
    async fetchSellers() {
      const { sellers, error } = await SellerService().index();

      if (error) this.hasError = true;
      else {
        this.sellers = sellers;
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
.el-card {
  width: 100%;
}
tr.unavailable {
  background-color: #fffde7;
}
tr.problem {
  background-color: #fbe9e7;
}
</style>