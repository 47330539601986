<template>
  <el-dialog :title="ModalTitle" v-model="shouldShow">
    <form>
      <el-row type="flex" justify="space-between">
        <base-input label="Nome:" v-model="seller_.name"> </base-input>
      </el-row>
      <el-select
        @visible-change="(shouldFetch) => shouldFetch && fetchUsers()"
        v-model="seller_.user_id"
        size="medium"
        clearable
        value-key="uid"
      >
        <template #prefix>Vincular usuário:</template>
        <el-option
          v-for="item in Users"
          :key="item.uid"
          :label="item.name"
          :value="item.uid"
        >
        </el-option>
      </el-select>
    </form>
    <template #footer>
      <el-row justify="space-between" type="flex">
        <el-button type="text" class="button" @click="shouldShow = false"
          >Cancelar</el-button
        >
        <el-button
          type="text"
          :isLoading="isLoadingSave"
          class="button"
          @click="save"
          >Salvar</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>
<script>
//import BaseInput from "../../components/BaseInput.vue";
import { ElNotification } from "element-plus";
import SellerService from "../../services/sellers";
import UserService from "../../services/users";
import BaseInput from "../../components/BaseInput.vue";

export default {
  props: ["seller", "showModal"],
  components: { BaseInput },
  emits: ["close-modal", "should-update"],
  data() {
    return {
      seller_: {},
      users: [],
      isLoadingSave: false,
    };
  },
  watch: {
    seller(v) {
      this.seller_ = v || {};

      if (v?.user) {
        this.seller_.user_id = v.user.uid;
        this.users = [v.user];
      }
    },
  },
  computed: {
    ModalTitle() {
      return !this.isNew ? "Editar vendedor" : "Cadastrar vendedor";
    },
    isNew() {
      return !this.seller;
    },
    Users() {
      return this.users || [];
    },
    shouldShow: {
      get() {
        return this.showModal;
      },
      set() {
        this.$emit("close-modal");
      },
    },
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    async fetchUsers() {
      const { users } = await UserService().index();

      if (users) this.users = users;
    },
    save() {
      this.isLoadingSave = true;
      return !this.isNew ? this.updateSeller() : this.createSeller();
    },
    async createSeller() {
      const { seller, error } = await SellerService().create(this.seller_);

      if (error)
        ElNotification.error({
          title: "Não foi possível cadastrar o vendedor",
          message: error.message,
          position: "bottom-right",
        });
      else {
        this.$emit("should-update");
        this.shouldShow = false;
        this.seller_ = {};
        ElNotification.success({
          title: seller.message,
          position: "bottom-right",
        });
        this.$emit("close-modal");
        this.isLoadingSave = false;
      }
    },
    async updateSeller() {
      const { seller, error } = await SellerService(this.seller_.uid).update(
        this.seller_
      );

      if (error)
        ElNotification.error({
          title: "Não foi possível alterar o vendedor",
          message: error.message,
          position: "bottom-right",
        });
      else {
        this.$emit("should-update");
        ElNotification.success({
          title: seller.message,
          position: "bottom-right",
        });
        this.$emit("should-update");
        this.$emit("close-modal");
        this.isLoadingSave = false;
      }
    },
  },
  name: "SellerModal",
};
</script>
<style scoped>
.el-button {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.el-input {
  width: 100% !important;
  margin-top: 5px !important;
}
.el-select {
  max-height: 36px;
  margin-top: 5px !important;
  display: block !important;
}
.checkbox-margin-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>